import { apm } from "@elastic/apm-rum"
import HCaptcha, { HCaptchaProps } from "@hcaptcha/react-hcaptcha"
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { appConfig, appInit } from "../../../config"
import { useAppState } from "../../../utils/hooks"

const useCaptcha = () => {
    const captchaRef = useRef<HCaptcha>(null)
    const [configReady, setConfigReady] = useState(false)
    const { language } = useAppState()

    const lang = useMemo(() => {
        switch (language) {
            case 'zh-Hans': return 'zh-CN'
            case 'zh-Hant': return 'zh-TW'
            default: return language
        }
    }, [language])

    useEffect(() => {
        const checkConfig = async () => {
            await appInit
            setConfigReady(true)
        }
        checkConfig()
    }, [])


    //https://cn1.hcaptcha.com/1/api.js?endpoint=https://cn1.hcaptcha.com&assethost=https://assets-cn1.hcaptcha.com&imghost=https://imgs-cn1.hcaptcha.com&reportapi=https://reportapi-cn1.hcaptcha.com
    const captcha = useMemo(() => {
        const props: HCaptchaProps = {
            sitekey: appConfig.REACT_APP_HCAPTCHA_KEY,
            onVerify: () => { },
            size: "invisible",
            languageOverride: lang,
        }
        const host = window.location.hostname
        const isChina = [
            /thinkmarkets\.asia/,
            /thinkmarketschina/,
            /portal-uat/, //for testing
            /thinkmarkets\.cn/
        ].some(expr => expr.test(host))

        if (isChina) {
            props.apihost = "https://cn1.hcaptcha.com"
            props.endpoint = "https://cn1.hcaptcha.com"
            props.assethost = "https://assets-cn1.hcaptcha.com"
            props.imghost = "https://imgs-cn1.hcaptcha.com"
            props.reportapi = "https://reportapi-cn1.hcaptcha.com"
        }

        return configReady
            ? <HCaptcha
                ref={captchaRef}
                {...props}

            /> : null
    }, [configReady, lang])

    const reset = useCallback(() => {
        if (captchaRef.current)
            captchaRef.current.resetCaptcha()
    }, [])

    const execute = useCallback(async () => {
        if (!captchaRef.current) {
            const error = 'no captcha script loaded'
            apm.captureError(error)
            return error
        }
        const response = await captchaRef.current.execute({ async: true })
        return response.response
    }, [])

    return { captcha, reset, executeCaptcha: execute }
}

export { useCaptcha }
